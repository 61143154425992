@font-face {
    font-family:'Manrope' ;
    src: url('/public/fonts/Manrope-Regular.ttf');
}
#title_H2{
    display: flex;
    align-items: center;
    margin: 30px 0;
    
}
#title_H2_2{
    background: linear-gradient(265deg, rgba(118,0,89,1) 0%, rgba(5,127,83,1) 50%, rgba(5,49,138,1) 100%);
    width: fit-content;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}
#title_H2 span{
    background: linear-gradient(265deg, rgba(118,0,89,1) 0%, rgba(5,127,83,1) 50%, rgba(5,49,138,1) 100%);
    width: fit-content;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}
#modal-title,#title_H3{
    display: flex;
    align-items: center;
}
#title_H3{
    margin:20px 0;
}
#modal-title span{
    background: linear-gradient(265deg, rgba(118,0,89,1) 0%, rgba(5,127,83,1) 50%, rgba(5,49,138,1) 100%);
    width: fit-content;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.action_bottons{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.action_bottons h6{
    display: flex;
    align-items: center;
}
.Cascader_container{
    margin-top: 20px;
}
.Cascader_comp{
    width: 100%;
    border:2px solid rgb(13, 138, 134);
    border-radius: 8px;
    outline: none;
    color:rgb(9, 215, 146);
    z-index: 100000;
}
.apply_Button{
    width: 100%;
    background: linear-gradient(265deg, rgba(5,127,83,1) 0%, rgba(95,5,138,1) 100%);
}
.Add_container{
    width: 100%;
    padding: 30px 0;
    display: flex;
    justify-content: center;
}
.Add_container .add_Button{
    width: 50%;
    background: linear-gradient(265deg, rgba(5,127,83,1) 0%, rgba(95,5,138,1) 100%);

}
.pagination_container{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 30px;
}
.table_container{
    width:100%;
}

.Link_breadcrumbs h5{
    font-family:'Manrope' ;
    color: rgb(55, 55, 55);
}
.table_row:hover{
    cursor: pointer;
}

.action_buttons_validate_cancel{
    margin-top:20px;
    display: flex;
    justify-content: space-evenly;
    

}

.cancelBtn{
    background:#fc1c1cb9 !important;
}
.checkBtn{
    background:#0dc07bb9 !important;
}


.title_image{
    display:flex;
    justify-content: space-between;
}
.title_image img{
    width: 200px;
}
.te{
    color:rgb(239, 238, 238)
}