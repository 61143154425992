.App {
  width: 100%;
}

.body_container {
  display: flex;
  width: 100%;
}

.body_container .sidebar_container {
  position: fixed;
  height: 100%;
  z-index: 10000;
  /* background:red; */
}

.body_container .routes_container {
  padding: 20px;
  margin-top: 55px;
  transition: margin-left 0.3s, width 0.3s;
}

.sidebar-expanded .sidebar_container {
  width: 260px; /* Your sidebar expanded width */
}

.sidebar-expanded .routes_container {
  margin-left: 260px; /* Same as sidebar expanded width */
  width: calc(100% - 260px); /* Adjust remaining width */
}

.sidebar-collapsed .sidebar_container {
  width: 60px; /* Your sidebar collapsed width */
}

.sidebar-collapsed .routes_container {
  margin-left: 60px; /* Same as sidebar collapsed width */
  width: calc(100% - 60px); /* Adjust remaining width */
}
.zsc{
  background:rgba(255, 255, 255, 0.529);
  background:linear-gradient(124deg, rgba(12,46,96,1) 0%, rgba(38,86,17,1) 46%, rgba(9,46,100,1) 100%);
  color:(linear-gradient(124deg, rgb(6, 51, 147) 0%, rgb(8, 106, 63) 50%, rgb(128, 25, 117)100%))
}