.contact-us {
    width: 100%;
    height: 85vh;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .contact-us .form_container,.image_container{
    width:50%;
  }
  .image_container #imgContact{
    width: 100%;
  }
  .contact-us .ContactH1 {
    text-align: center;
    margin-bottom: 20px;
    background: linear-gradient(265deg, rgba(118,0,89,1) 0%, rgba(5,127,83,1) 50%, rgba(5,49,138,1) 100%);
    width: fit-content;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .rs-form-group {
    margin-bottom: 15px;
  }
  
  .rs-btn {
    width: 100%;
    padding: 10px;
  }
  
  .rs-message {
    margin-bottom: 20px;
  }
  

  @media (max-width: 984px){
    
    .contact-us .form_container{
        width:100%;
        z-index: 100;
    }
    .contact-us .image_container{
        width: 100%;
        position:absolute;
        z-index: 99;
        filter: blur(25px);
        transition: all 0.3s;
    }

}