
.Nav_container {
    width:100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position:fixed !important;
    backdrop-filter: blur(10px);
    z-index: 100000;
    transition: all 0.3s;
    background:linear-gradient(124deg, rgba(7,28,75,1) 0%, rgba(9,100,60,1) 100%);
    padding: 10px 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo {
    display: inline;
    color: #ecf0f1;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

nav{
    /* background:rgb(27, 214, 251); */
    width: 80%;
    display: flex;
    justify-content: space-between;
}

nav .nav_links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    width:90%
}
.signInBtn{
    background: linear-gradient(124deg, rgba(12,46,96,1) 0%, rgba(38,86,17,1) 46%, rgba(9,46,100,1) 100%);
    width: 150px;
    transition:all 0.5s;
    
}
.signInBtn:hover{
    background: linear-gradient(124deg, rgba(38,86,17,1)  0%, rgba(12,46,96,1) 46%,rgba(38,86,17,1) 100%);
    
}


nav .nav_links .navbar_links {
    color: #ffffff;
    text-decoration: none;
    padding: 5px 15px;
    /* border-radius: 5px; */
    transition: background-color 0.3s ease, color 0.3s ease;
    display:flex;
    justify-content:space-around;
    align-items: center;
    width:fit-content !important;
}

nav .nav_links .navbar_links.active {
    color: #0acd9c;
    border-bottom: 2px solid rgb(5, 207, 207) ;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-weight: 900;
    /* font-size: 1.2rem; */
}
nav .nav_links .navbar_links:hover {
    /* background:  linear-gradient(124deg, rgba(12,46,96,1) 0%, rgba(38,86,17,1) 46%, rgba(9,46,100,1) 100%); */
    color: #05fcb2;
    border-bottom: 2px solid rgb(5, 207, 207) ;
    text-shadow: 0 0 3px #05fcb2;
}

#toggleButton{
    display:none;

}

.navButtons_div{
    width:30%;
    /* background-color: #c22df8; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    right: 0;
}
.navButtons_div .SelectLanguage{
    height: 40px !important;
    width: 145px !important;
}

@media (max-width: 984px) {
    .navButtons_div{
        margin-right: 100px;
    }
    .Nav_container {
        flex-direction: column;
        overflow: hidden;
        z-index: 1000000;
    }
    #toggleButton{
        display: inline-flex;
        position: absolute;
        top:7px;
        right:17px;
    }
    #signUpButton{
        display: none;
    }
    nav{
        /* background:red; */
        width: 100%;
        display: flex;
        justify-content: center;
    }

    nav .nav_links {
        flex-direction: column;
        /* align-items: flex-end; */
        gap: 10px;
        margin-top: 50px;
        /* background: green ; */
        
    }
    nav .nav_links .navbar_links{
        /* position: absolute; */
        /* background: purple; */
        width: 100% !important;
        /* display: flex; */

        gap: 50px;
    }
    
    .logo {
        
        position: absolute;
        left:5px;
        top:8px;
        /* margin-bottom: 10px; */
        /* margin-top: -10px; */
    }

    .menuProfile{
        position: fixed;
        right: 20px;
    }

    .SelectLanguage{
        position: fixed !important;
        right: 150px;
        top: 8px;
        width: 105px !important;
    }

    #sheet {
        width:70%;
        background:'red'
    }
    #sheet .image_wavy img{
        width: 100% !important;
        object-fit: fill;
    }
    .form_model{
        width:100% !important;
        z-index: 100;
        
    }
    
    #sheet .image_wavy{
        width: 100% !important;
        position:absolute;
        z-index: 99;
        filter: blur(15px);
        transition: all 0.3s;
    }

    .nav_icons{
        position:absolute;
        left:50px
    }
    

    .nav_spans{
        position: absolute;
        right:50px;
    }
}

.form_model h1{
    text-align: center;
    margin-bottom: 20px;
    background: linear-gradient(265deg, rgb(88, 5, 115) 0%, rgb(21, 107, 76) 50%, rgb(5, 48, 135) 100%);
    width: fit-content;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

/*sign up button*/
/* styles.css */
@keyframes gradient-border {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .signInBtn {
    position: relative;
    border: 2px solid transparent;
    border-radius: 20px;
    /* background: white; */
  }
  
  .signInBtn::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(270deg, #095b8b, #0c6020, #c22df8);
    background-size: 600% 600%;
    animation: gradient-border 5s ease infinite;
    z-index: -1;
    border-radius: inherit;
    padding: 2px;
  }
  .zdc{
    color:rgb(21, 255, 169);
    background:#088fa6;
  }