@font-face {
  font-family: 'Roboto-Light';
  src: url('/public/fonts/Roboto-Light.ttf');
}
*{
  font-family:'Roboto-Light';
}
body {
  margin: 0;
  padding: 0;
  /* overflow:hidden; */
  overflow-x: hidden;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
/* Style the scrollbar for webkit browsers */
body::-webkit-scrollbar {
  width: 8px; /* Thin scrollbar */
}

body::-webkit-scrollbar-track {
  background: #313131; /* Track color */
}

body::-webkit-scrollbar-thumb {
  background: #b0b0b0; /* Scrollbar thumb color */
  border-radius: 5px; /* Rounded scrollbar thumb */
  border: none; /* Border around the thumb */
}

body::-webkit-scrollbar-thumb:hover {
  background: #c7c6c6; /* Darker color on hover */
}

