.how-it-works {
    text-align: center;
    /* padding: 40px; */
  }
  
  .how-it-works .howH1 {
    /* font-size: 2.5em; */
    margin-bottom: 20px;
  }
  
  .how-it-works .howPar {
    font-size: 1.2em;
    margin-bottom: 40px;
  }
  
  .how-it-works .howH1,.howPar{
    background: linear-gradient(265deg, rgba(118,0,89,1) 0%, rgba(5,127,83,1) 50%, rgba(5,49,138,1) 100%);
    width: fit-content;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    padding-bottom: 10px;
  }

  .steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .step {
    flex: 1 1 300px;
    width: 350px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .step .HowPics {
    width: 350px;
    border-radius: 10px;
  }
  
  .step h2 {
    font-size: 1.5em;
    margin: 20px 0 10px;
  }
  
  .step p {
    font-size: 1em;
    color: #555;
  }
  .zscx{
    color:rgba(176, 175, 175, 0.786)
  }