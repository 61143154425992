.landing_container{
    background:linear-gradient(124deg, rgba(7,28,75,1) 0%, rgba(9,100,60,1) 100%);
    width:102%;
    margin:-20px;
    padding:10px;
    min-height:90dvh;
    display:flex;
    justify-content:space-around;
    align-items:center;
    overflow-x: hidden !important;
}
.landing_container .hero_section{
    width:50%;
    color:white;
    font-weight:900;
    font-size:4rem;
    text-align:center;
    /* padding: 0 0 0 120px; */
    /* background:red; */
    /* margin-right: -30px; */

}
.landing_container .image_section{
    width:50%;
    /* margin-left: -10px; */
    height:50vh;
    /* background:red; */
    padding-left: 30px;
}
.landing_container .image_section #imgHouse{
    width:100%;
    position:relative;
    
}

.landing_container .image_section #imgHouse2{
    width:100%;
    position:relative;
    height: 50vh;
    
    
}
                
@media (max-width: 984px){
    .landing_container{
        width:100%;
        margin:-20px;
        padding:10px;
        min-height:100dvh;
    }
    .landing_container .hero_section{
        width:100%;
        z-index: 100;
        margin-right:-20px;
    }
    .landing_container .image_section{
        width: 100%;
        position:absolute;
        z-index: 99;
        filter: blur(15px);
        transition: all 0.3s;
    }

}


.imgHH{
    position: relative;
    z-index: 1000;
    width: 40%;
    height: 150px;
}