.logoVille{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    height: 50px;
    align-items: center;
}
.Dropdown_items{
    width: 100%;
    height: 50px;
}
.main_links{
    width: 100%;
    height: 50px;
    /* padding-bottom: 10px; */
    padding-left: 50px;
    position: absolute;
    font-weight: bold;

    left:0;
    color:rgb(46, 46, 46);
    display: flex;
    align-items: center;

}
.main_links.active{
    background:linear-gradient(124deg, rgba(7,28,75,1) 0%, rgba(9,100,60,1) 100%);
    align-items: center;
    font-weight: bold;
    padding-bottom: 5px;
    width: 100%;
    position: absolute;
    left:0;
    color: white;
}

.Dropdown_items .main_links{
    width: 100%;
    height: 50px;
    /* padding-bottom: 10px; */
    padding-left: 50px;
    position: absolute;
    font-weight: bold;
    left:0;
    color:rgb(46, 46, 46);
    display: flex;
    align-items: center;
}
.dash_links{
    color: rgb(46, 46, 46);
    font-weight: bold;

}
.dash_links:hover{
    color: rgb(46, 46, 46);
    font-weight: bold;
    text-decoration: none;
}
.dash_links.active{
    background:linear-gradient(124deg, rgba(7,28,75,1) 0%, rgba(9,100,60,1) 100%);
    color: white;
}
.Dropdown_items .main_links.active{
    background:linear-gradient(124deg, rgba(7,28,75,1) 0%, rgba(9,100,60,1) 100%);
    align-items: center;
    font-weight: bold;
    padding-bottom: 5px;
    width: 100%;
    position: absolute;
    left:0;
    color: white;
}

.Dropdown_item_workspace .workspace_links{
    width: 100%;
    height: 50px;
    /* padding-bottom: 10px; */
    padding-left: 50px;
    position: relative;
    margin-left:-55px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: bold;
    /* fontWeight: 'bold'; */
}
.workspace_links span{
    
    color:rgb(46, 46, 46);
}
.workspace_links.active{
    background:linear-gradient(124deg, rgba(7,28,75,1) 0%, rgba(9,100,60,1) 100%);
    font-weight: bold;
    width: 120%;
    position: relative;
    margin-right:-30px;
    left:0;
    color: white;
}
.workspace_links.active span{
    color: white;
    text-decoration: none;
}
.Sidenav{
    height:93%;
    
}


/* Style the scrollbar for webkit browsers */
.sidenav_body::-webkit-scrollbar {
    width: 8px; /* Thin scrollbar */
  }
  
  .sidenav_body::-webkit-scrollbar-track {
    background: #f0f0f0; /* Track color */
  }
  
  .sidenav_body::-webkit-scrollbar-thumb {
    background: #b0b0b0; /* Scrollbar thumb color */
    border-radius: 5px; /* Rounded scrollbar thumb */
    border: 2px solid #f0f0f0; /* Border around the thumb */
  }
  
  .sidenav_body::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0; /* Darker color on hover */
  }

@media (max-width: 984px) {
    .Sidenav{
        height:93% !important;
    }
}